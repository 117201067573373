/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {AuditoriaSCH} from "../../search/AuditoriaSCH"
import {Filtro} from "../../models/filtro/filtro";
import {getBaseSCH} from "../../search/BaseSCH";
import {LogAudit} from "../../models/log_audit";

const url = '/auditoria';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  auditoria: [],
  logs_audit: new LogAudit()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAuditorias: (state) => {
    return state.all
  },
  pegarAuditoria: (state) => {
    return state.auditoria
  },
};

const actions = {
  setLog({commit}, log_audit) {
    commit('SET_LOG', log_audit)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['nome','rotina','nome_tabela'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getAuditoria({commit}, {id,onSucesso,onErro}) {

    try {
      let filtro = getBaseSCH('log_audit_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_AUDITORIA', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AuditoriaSCH(perPage))
  },

  async setAllAuditorias ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      // console.log('SET_AUDITORIAS:', list.data);
      commit('SET_AUDITORIAS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setAuditoria({commit}, auditoria) {
    commit('SET_AUDITORIA', auditoria)
  },

};

const mutations = {
  SET_LOG(state, log) {
    state.logs_audit = log
  },
  SET_AUDITORIAS (state, auditoria) {
    state.all = auditoria
  },
  SET_AUDITORIA (state, auditoria) {
    state.auditoria = auditoria
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
