/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import usuario from './modules/usuarios'
import auditoria from "./modules/auditoria";
import licenca from "./modules/licenca";
import cliente from "./modules/cliente";
import software from "./modules/software";
import error_logs from "./modules/error_logs";
import acesso from "./modules/acesso";
import backups from './modules/backups'
import relatorio from './modules/relatorio'
import analises from './modules/analises'
import paineis from './modules/paineis'

export default createStore({
   modules: {
      geral,
      token,
      usuario,
      acesso,
      licenca,
      cliente,
      analises,
      paineis,
      software,
      relatorio,
      auditoria,
      error_logs,
      backups
   }
})
