import {mapGetters} from 'vuex'
import router from '../router/router'
import {EventBus} from './event-bus'


export default {
  computed: {},
  data: () => ({
    dialogPdf: false,
    show_progress: false,
    editando: false,
    form: false,
    show_list: false,
    listaItens: [],
    notificacoes: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
  }),
  methods: {
    abrePDFNovaAba(response){
      const file = new Blob([response.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank')
    },
    removerMascara(str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    removerAllMask(str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^a-zA-Z0-9]+/g, '')
      } else {
        return ''
      }
    },
    isString(str) {
      return str.match(/[a-zA-Z]+/g) !== null
    },
    retornoValido(campo, retorno) {
      return this.validarCampo(campo) ? campo : retorno
    },
    sair(path) {
      router.push({path: path}).catch(err => {
      })
    },
    retorno(item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    contarPeriodo(now, past, total=30) {
      var g1 = new Date(now)
      var g2 = new Date(past)
      var diff = Math.abs(g1.getTime() - g2.getTime())
      var days = Math.ceil(diff / (1000 * 3600 * 24))
      return days > total
    },
    contarDias(now, past) {
      var g1 = new Date(now)
      var g2 = new Date(past)
      var diff = Math.abs(g1.getTime() - g2.getTime())
      var days = Math.ceil(diff / (1000 * 3600 * 24))
      return days
    },
    getHoraAtual() {
      var dNow = new Date()
      var h = this.zeroEsquerda(dNow.getHours(), 2)
      var m = this.zeroEsquerda(dNow.getMinutes(), 2)
      var s = this.zeroEsquerda(dNow.getSeconds(), 2)
      return h + ':' + m + ':' + s
    },


    setPeriodo(dias, acao) {
      var date = new Date()
      if (acao === '+') {
        date.setDate(date.getDate() + parseInt(dias))
      } else {
        date.setDate(date.getDate() - parseInt(dias))
      }
      return this.setDataAtual(date)
    },

    ordenarListaAsc(lista, chave, tipo = 'asc') {
      return lista.sort(function (a, b) {
        if (tipo === 'asc') {
          if (a[chave] > b[chave]) {
            return 1;
          }
          if (a[chave] < b[chave]) {
            return -1;
          }
        } else {
          if (a[chave] < b[chave]) {
            return 1;
          }
          if (a[chave] > b[chave]) {
            return -1;
          }
        }
        return 0;
      })
    },
    removeDuplicates(array,key) {
      let newArray = []
      let uniqueObject = {}
      for (let i in array) {
        var objTitle = array[i][key]
        uniqueObject[objTitle] = array[i]
      }
      for (var i in uniqueObject) {
        newArray.push(uniqueObject[i])
      }
      return newArray
    },
    maskCpfCnpj(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
        } else {
          return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
        }
      }
    },
    maskData(data) {
      return data.replace(/(\d{2})(\d{2})(\d{4})/g, '\$1/\$2/\$3')
    },
    maskCep(cpf) {
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },
    toIntNaN(v) {
      if (isNaN(parseInt(v))) {
        return 0
      } else {
        if (this.validarCampo(v)) {
          return parseInt(v)
        } else {
          return 0
        }
      }

    },
    maskTel(cpf) {
      if (this.validarCampo(cpf)) {
        return cpf.replace(/(\d{2})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
      }
    },

    focusInput(id) {
      let loadTime = 10
      window.onload = function() {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart
      }
      let interval = setInterval(function() {
        let muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }
        clearInterval(interval)
      }, loadTime)
    },
    getDataAtual() {
      let d = this.zeroEsquerda(new Date().getDate(), 2)
      let m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
      let a = new Date().getFullYear()
      return a + '-' + m + '-' + d
    },

    getHora() {
      let h = this.zeroEsquerda(new Date().getHours(), 2)
      let m = this.zeroEsquerda(new Date().getMinutes(), 2)
      return h + ':' + m
    },
    gerarData(tipo = true, dia, mes, ano) {
      var data = new Date()
      let d = this.validarCampo(dia) ? dia : data.getDate()
      let m = this.validarCampo(mes) ? mes : (data.getMonth() + 1)
      let a = this.validarCampo(ano) ? ano : data.getFullYear()

      if (tipo) {
        return a + '-' + this.zeroEsquerda(m, 2) + '-' + this.zeroEsquerda(d, 2)
      } else {
        return a + '-' + m + '-' + d
      }
    },
    getUltimoDia(data) {
      let d = new Date(data)
      let ultimoDia = new Date(d.getFullYear(), (d.getMonth() + 1), 0)
      return this.setDataAtual(ultimoDia)
    },

    getDataHora(dt){
      var h = this.extrairData2(dt,1)
      h = this.validarCampo(h) ? h : '00:00:00'
      return this.inverterData(this.extrairData2(dt)) + ' ' + h
    },

    extrairData2(data, index = 0) {
      let dt = ''
      if (this.validarCampo(data)) {
        let d = data.split(' ')
        dt = d[index]
      }
      return dt
    },
    setDataAtual(data) {
      let d = this.zeroEsquerda(data.getDate(), 2)
      let m = this.zeroEsquerda(data.getMonth() + 1, 2)
      let a = data.getFullYear()
      return a + '-' + m + '-' + d
    },
    inverterData(newData) {
      var xvals = ''
      if (this.validarCampo(newData)) {
        xvals = newData.split('-')
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },
    inverterData2(newData) {
      newData = this.maskData(newData);
      let xvals = newData.split('/');
      let dia = ("00" + parseInt(xvals[0])).slice(-2);
      let mes = ("00" + parseInt(xvals[1])).slice(-2);
      let ano = parseInt(xvals[2]);
      return ano + '-' + mes + '-' + dia
    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    getValueByKey(item, key) {
      if (item[key] === undefined) {
        return item
      } else {
        return item[key]
      }
    },
    setZeros(v, tam = 5) {
      if (this.validarCampo(v)) {
        var valor = parseInt(v) + ''
        return this.zeroEsquerda(valor, tam)
      }
    },
    logs(obj) {
      if (this.validarCampo(obj)) {
        return JSON.parse(JSON.stringify(obj))
      } else {
        return obj
      }
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function() {
        _this.show_alerta = false
        clearInterval(intervalo)
      }, 6000)
    },
    toDecimal(v, tam = 2, valida_nan = false) {
      try {
        if (valida_nan) {
          if (isNaN(parseFloat(v))) {
            return 0
          } else {
            if (this.validarCampo(v)) {
              return parseFloat(parseFloat(v).toFixed(tam))
            } else {
              return 0
            }
          }
        } else {
          let ret = 0
          if (this.validarCampo(v)) {
            if (!this.validarCampo(tam)) tam = 2
            ret = parseFloat(parseFloat(v).toFixed(tam))
          }
          return ret
        }
      } catch (e) {
        console.log(e)
      }
    },
    fechar_alerta() {
      this.show_alerta = false
    },

    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    scrollTop() {
      var elmnt = document.getElementById('inspire')
      elmnt.scrollIntoView({behavior: 'smooth'})
    },
    enterTab(InputId, e) {
      e = e || window.event
      const code = e.which || e.keyCode
      if (code === 13) {
        var el = document.getElementById(InputId)
        if (el !== null) {
          el.focus()

        }
      }
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },

    mobileModal(size) {
      var width = window.screen.width
      if (width <= 400) {
        size = '100%'
      } else if (width >= 400 && width <= 600) {
        size = '90%'
      } else if (width >= 601 && width <= 900) {
        size = '80%'
      } else if (width >= 901 && width <= 1300) {
        size = '70%'
      }
      return size
    },

    returnValue(valor, ret = 0) {
      return this.validarCampo(valor) ? valor : ret
    },
    getData(data) {
      return this.validarCampo(data) ? (this.inverterData(data.substr(0, 10)) + '' + data.substr(10, 6)) : ''
    },
    extrairData(data) {
      let date = new Date(data.substr(0, 10))
      let d = this.zeroEsquerda(date.getDate(), 2)
      let m = this.zeroEsquerda(date.getMonth() + 1, 2)
      let a = date.getFullYear()
      return a + '-' + m + '-' + d
    },

    compararData(dt_inicial, dt_final) {
      let g1 = new Date(dt_inicial)
      let g2 = new Date(dt_final)
      return g1.getTime() > g2.getTime()
    },

    addDias(data, dias) {
      let date = new Date(data)
      date.setDate(date.getDate() + dias)
      return this.setDataAtual(date)
    },
    compareObjects(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },

    areObjectsEqual(obj1, obj2) {
      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);

      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }

      for (let key of obj1Keys) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },
    findDifferences(arr1, arr2) {
      const differences = [];

      var _this = this
      arr1.forEach(item1 => {
        const match = arr2.find(item2 => _this.areObjectsEqual(item1, item2));
        if (!match) {
          differences.push(item1);
        }
      });

      arr2.forEach(item2 => {
        const match = arr1.find(item1 => _this.areObjectsEqual(item1, item2));
        if (!match) {
          differences.push(item2);
        }
      });

      return differences;
    },

    closeAllNotifications(){
      var array = JSON.parse(sessionStorage.getItem('activeNotifications'))
      if (array.length>0) {
        array.forEach(notification => {
          notification.close();
        });
      }
      sessionStorage.setItem('activeNotifications', '[]')
    },

    insertOrUpdateNotifications(item){
      var array = JSON.parse(sessionStorage.getItem('activeNotifications'))
      if(array.length > 0){
        let pos = array.indexOf(item)
        if (pos>=0) {
          array.splice(pos, 1,item)
        }else {
          array.push(item)
        }
      } else {
        array.push(item)
      }
      sessionStorage.setItem('activeNotifications', JSON.stringify(array))

    },
    notifyme(titulo='', texto='', error_log_id='') {
      var _this = this

      Notification.requestPermission(function() {
        var url =encodeURI(window.location.origin + '/favicon.png');
        var not = new Notification(titulo, {
          icon: url ,
          body: texto,
          requireInteraction: true // Notificação persistente até ser fechada pelo usuário
        })

        // this.modalErro = false
        // not.onshow = function() {
        //   setTimeout(() => {
        //     if (not) {
        //       not.close();
        //     }
        //   }, 60*1000);
        // };
        not.onclick = function() {
          var silenciados = JSON.parse(sessionStorage.getItem('silenciados'))
          silenciados.push(error_log_id)
          sessionStorage.setItem('silenciados', JSON.stringify(silenciados))

          EventBus.$emit('ERROR_LOGS', error_log_id)

        }

        not.onclose = () => {
          var silenciados = JSON.parse(sessionStorage.getItem('silenciados'))
          silenciados.push(error_log_id)
          sessionStorage.setItem('silenciados', JSON.stringify(silenciados))
        };

        _this.insertOrUpdateNotifications(not)

      })
    }
  },
}
