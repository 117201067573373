/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
   headers: {
      ContentType: 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      UserId: sessionStorage.getItem('usuario_id'),
      AcaoId: sessionStorage.getItem('Acaoid'),
   },
   baseURL: process.env.VUE_APP_API_URL,
   BASE_URL: '/'
})

export function setToken(token, userID) {
   sessionStorage.setItem('token', token)
   API.defaults.headers.Authorization = `Bearer ${token}`
   API.defaults.headers.UserId = userID
}

