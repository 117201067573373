/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getRelatorioVersaoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}


export const RelatorioVersaoSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['*']
  return filtro
}
