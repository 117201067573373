/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {PainelSCH} from '../../search/PainelSCH'
import {Filtro} from '../../models/filtro/filtro'
import {getBaseSCH} from '../../search/BaseSCH'

const url = '/painel';

const state = {
  all: [],
  filtro: new Filtro(),
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAll: (state) => {
    return state.all
  },
};

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['fantasia','message', 'protocol'],
      '%'+search+'%',
      'ilike'
    )
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PainelSCH(perPage))
  },


  async setAllPainel ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      // console.log('SET_PAINEL:', list);
      commit('SET_PAINEL', list.data);
      onSucesso()
    } catch (error) {
      onErro([])
    }
  },

};

const mutations = {
  SET_PAINEL(state, error_log) {
    state.all = error_log
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
