/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {SoftwareSCH, getSoftwareSCH} from "../../search/SoftwareSCH"
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {Software} from '../../models/software'
import {EventBus} from '../../helpers/event-bus'

const url = '/software'

const state = {
  all: [],
  allList: [],
  software: new Software(),
  filtro: new Filtro()
}

const getters = {
  listaAllSoftwares: (state) => {
    return state.allList !== null && state.allList !== undefined ? state.allList : []
  },
  listaSoftwares: (state) => {
    return state.all
  },
  pegaSoftware: (state) => {
    return state.software
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['software_key','descricao'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getSoftwareSCH('software_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_SOFTWARE', list.data[0])
    EventBus.$emit('GET_SOFTWARE_CONCLUIDO', true)

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SoftwareSCH(perPage))
  },
  async setAllListSoftwares({commit},{onSucesso}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    onSucesso()
  },
  async setSoftwaresCompleto({commit}) {
    const list = (await lista(url + '/getAll')).data
    commit('SET_ALL_LIST', list)
  },
  async setAllSoftwares({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_SOFTWARES', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async processarSoftware({commit}, {acao, onSucesso, onErro}) {
    try {

      let res = await processar(url, state.software, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setSoftware({commit}, software) {
    commit('SET_SOFTWARE', software)
  },
}

const mutations = {
  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_SOFTWARES(state, softwares) {
    state.all = softwares
  },
  SET_SOFTWARE(state, software) {
    state.software = software
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
