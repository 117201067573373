import {Filtro} from "../models/filtro/filtro";
export const  getPainelSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.fields = ['*'];
  return filtro
};

export const PainelSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  return filtro
};
