/* eslint-disable */
import {nao_autorizado, pegaDados, processaReport} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {AcessoSCH, getAcessoSCH} from '../../search/AcessoSCH'
import {Filtro} from '../../models/filtro/filtro'
import {RelatorioVersaoSCH} from '../../search/RelatorioVersaoSCH'

const url = 'relatorio'

const state = {
  all: [],
  filtro: new Filtro(),
}

const getters = {
  listaRelatorioVersao: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}
const actions = {

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RelatorioVersaoSCH(perPage))
  },

  async getPdf({commit},{dados,onSucesso,onErro}) {
    try {
      const retorno = (await processaReport(url + '/get_relatorio', dados))
      onSucesso(retorno)
    } catch (error) {
      onErro()
    }
  },

  async setAllRelatorioVersao({commit},{filtro,onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', filtro)).data
      commit('SET_RELATORIO_VERSAO', list)
      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async getPercentualLicenca({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_perc_lic', [])).data
      onSucesso(list)
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async getPercentualSoftware({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_perc_soft', [])).data
      onSucesso(list)
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },

}

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_RELATORIO_VERSAO(state, all) {
    state.all = all
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
