/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, processaDados, nao_autorizado} from '../../services/GenericAPI'
import {LicencaSCH, getLicencaSCH, LicencaAtivaSCH} from '../../search/LicencaSCH'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {Licenca} from '../../models/licenca'
import {EventBus} from '../../helpers/event-bus'
import  router from '../../router/router'

const url = '/licenca'

const state = {
  all: [],
  allList: [],
  licenca: new Licenca(),
  filtro: new Filtro()
}

const getters = {
  listaAllLicencas: (state) => {
    return state.allList
  },
  listaLicencas: (state) => {
    return state.all
  },
  pegaLicenca: (state) => {
    return state.licenca
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
      '&unaccent(razao)',
      '&unaccent(fantasia)',
      '&unaccent(descricao)',
      'dias_restante',
      'cnpj_cpf',
      'mensalista_id',
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, {licenca_id,onSucesso}) {
    try {
    var filtro = getLicencaSCH('licenca_id', licenca_id, '=')
    const list = (await pegaDados(url+'/get', filtro)).data
    commit('SET_LICENCA', list.data[0])
    onSucesso()
    } catch (error) {
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', LicencaSCH(perPage))
  },
  setFiltroAtiva({commit}, perPage) {
    commit('SET_FILTRO', LicencaAtivaSCH(perPage))
  },
  async setAllListLicencas({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },
  async setDownload({commit}, {licenca, onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/download', licenca)).data
      onSucesso(list)
    } catch (error) {
      console.log(error)
      onErro()
    }
  },
  async setAllLicencas({commit}, {onSucesso,onErro}) {
    try {
      console.log('setAllLicencas:', state.filtro)
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      // console.log('SET_LICENCAS:', list.data)
      commit('SET_LICENCAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
      nao_autorizado(error)
    }
  },
  async processarOpcao({commit}, {licenca,path, onSucesso, onErro}) {
    try {
      const list = (await processaDados(url + '/' + path, licenca))
      this.dispatch('geral/setMsgSuccess', list.data.message)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
  },
  async setAllLicencasAtivas({commit}) {
    try {
      const list = (await pegaDados(url + '/get_ativas', state.filtro)).data
      commit('SET_LICENCAS', list)
    } catch (error) {
      console.log(error)
    }
  },
  async processarLicenca({commit}, {acao,onSucesso,onErro}) {
    try {

      let res = await processar(url, state.licenca, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setLicenca({commit}, licenca) {
    commit('SET_LICENCA', licenca)
  },
}

const mutations = {
  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_LICENCAS(state, licencas) {
    state.all = licencas
  },
  SET_LICENCA(state, licenca) {
    state.licenca = licenca
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
