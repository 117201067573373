/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Usuario {
  constructor () {
    this.usuario_id = uuidV4()
    this.nome = ''
    this.email = ''
    this.senha = ''
    this.bloqueado = false
    this.motivo = ''
  }
}
